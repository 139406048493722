.fancybutton {
    color: white;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    position: absolute;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    border: 2px solid white;
    border-radius: 36px;
    bottom: 0;
    left: 50%;
    line-height: 36px;
    //font-size: 14px;
    margin-left: -65px;
    -webkit-transition: opacity .3s ease, bottom .3s ease;
    transition: opacity .3s ease, bottom .3s ease;
    width: 128px;
    z-index: 1;
    text-align:center;
}

ul.filterable-gallery{
    list-style: none;
    padding: 0;
    margin: 36px -27px 36px;

  li {
      position: relative;
      margin-bottom: 20px;
      padding-left: 10px;
      padding-right: 10px;
      height: auto;

      a{
        display: block;
        overflow: hidden;
        background: #000;
      }

      .title {
        position: absolute;
        top:0;
        left:17px;
        width:100%;
        width:calc(100% - 34px);
        height:100%;
        opacity: 0;
        font-size: 16px;
        text-align: center;
        -moz-transform: scale(1.3);
        -ms-transform: scale(1.3);
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
        -moz-transition: -moz-transform 0.4s;
        -o-transition: -o-transform 0.4s;
        -webkit-transition: -webkit-transform 0.4s;
        transition: transform 0.4s;
        > div{
          position: relative;
           top: 50%;
           transform: translateY(-50%);
        }
        h2 {
            //padding-top: 40%;
            font-size: 32px;
            color:#fff;
        }

        hr {
            margin: 0 auto 20px auto;
            width: 60px;
            height: 0.1em;
            background: #fff;
        }
    }
    &:hover {
      .title {
          opacity: 1;
          transform: scale(1);
          // background-color: rgba(157,204,62,0.9);
      }
      img{
        // transform: scale(1.05);
        opacity: .35;
      }
      .fancybutton {
          bottom: 32px;
          filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
          opacity: 1;
          -webkit-transition: opacity .3s ease .1s, bottom .3s ease .1s;
          transition: opacity .3s ease .1s, bottom .3s ease .1s;
      }
    }
  }

  img {
    position: relative;
    display: block;
    width:100%;
    height:auto;
    opacity: .9;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    transform: scale(1);
  }
}

.port-1{width:50%;}

.more-projects{margin:0;}
