// Typography

@mixin text-emphasis-variant($parent, $color) {
  #{$parent} {
    color: $color;
  }
  a#{$parent} {
    @include hover-focus {
      color: darken($color, 10%);
    }
  }
}
