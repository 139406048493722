
@media (max-width: breakpoint-max(xs, $grid-breakpoints) ) {
  .text-right-xs{text-align: right;}
}

.embed-responsive-1by1 {
  padding-bottom: percentage(4 / 4);
}

.content-fill {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    img {
      &.pos-0{
        top: 0;
        left: 0px;
        width: 100%;
        height: auto;
        position: absolute;
      }
      &.pos-3{
        position: absolute;
        left: 0;
        right: 0;
        top: -9999px;
        bottom: -9999px;
        margin: auto 0;
        width: 100%;
      }
      &.pos-4{
        position: absolute;
        left: 0;
        right: 0;
        top: -9999px;
        bottom: -9999px;
        margin: auto 0;
        width: 100%;
      }
        &.pos-6{
          bottom: -33%;
          left: 0px;
          width: 100%;
          height: auto;
          position: absolute;
        }
        &.pos-7{
          bottom: 0;
          left: 0px;
          width: 100%;
          height: auto;
          position: absolute;
        }
    }
}

//Zoom Img
img[data-action="zoom"] {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
}
.zoom-img,
.zoom-img-wrap {
  position: relative;
  z-index: 666;
  @include transition(all 300ms);
}
img.zoom-img {
  cursor: pointer;
  cursor: -webkit-zoom-out;
  cursor: -moz-zoom-out;
}
.zoom-overlay {
  z-index: 420;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  filter: "alpha(opacity=0)";
  opacity: 0;
  @include transition(opacity 300ms);
}
.zoom-overlay-open .zoom-overlay {
  filter: "alpha(opacity=100)";
  opacity: 1;
}
.zoom-overlay-open,
.zoom-overlay-transitioning {
  cursor: default;
}
