header.homeIntro {
  padding: 60px 0 180px;
  background: #101010 url("../images/airy-home-edit.jpg") 50% 80% no-repeat;
  background-size: cover;
  position: relative;
  color: rgba(255,255,255,0.7);
  max-height:100vh;
  max-height:calc(100vh - 54px);
  @media (max-width: breakpoint-max(xs) ) {
    padding:10px 0 0;
    height:calc(100vh - 54px);
  }


  &:before{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.36);
    z-index: 0;
    content: ' ';
  }

  h1 {
    line-height: 55px;
    margin-bottom: 25px;
    font-weight: 600;
    font-size: 54px;
    color: #fff;
    letter-spacing: -0.05rem;
    @media (max-width: breakpoint-max(xs) ) {
      height:105px;
    }
  }

	.introWrap {
	  margin-top: 25px;
	  margin-bottom: 55px;
    @media (max-width: breakpoint-max(sm) ) {
    h1{font-size:32px;line-height: 1.1;}
    }
	  .buttonWrap {
		  text-align: left;
      a {
        margin:0 6px 6px 0;
        @media (max-width: breakpoint-max(sm) ) {
          margin: 0 0 20px;
        }
      }
		}
	}
  .subline {
    text-align: left;
    font-size: 20px;
    line-height: 28px;
    color: #fff;
    margin: 0 auto 50px auto;
    max-width: 700px;
  }
}


body.home .trustedBy {
  position: absolute;
  bottom: -161px;
  left: 17px;
  //display:none;
  @media screen and (max-width: 768px) and (min-width: 0px){
	  position: relative;
	  bottom: -50px;
	}

  .logos {
    margin-top: 15px;
    height: 58px;
    width: 365px;
    text-indent: -900em;
    background-image: url(../images/logostemp.png);
    background-size: 325px;
    background-repeat: no-repeat;
  }

	h2 {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
  	&:after {
  	  content: " ";
  	  display: inline-block;
  	  height: 1px;
  	  width: 300px;
  	  background: rgba(255,255,255,0.4);
  	  margin-left: 10px;
  	  position: relative;
  	  top: -4px;
  	}
  }

}

.homepage-section {
  padding: 95px 0 120px 0;
  margin-top:0;
  //float: left;
  width: 100%;
  //text-align: center;
  overflow: hidden;
  position: relative;
}


#customer-success {
  background-color: #3686be;
  padding: 93px 0 103px;
  width: 100%;
  text-align: center;
	h2 {
	  margin-bottom: 5px;
	  color: #fff;
    font-weight: 100;
    font-size: 36px;
	}

	ul {
	  display:table;
    padding:0;
    margin:40px 0 0 0;
	  li {
		  width: 16%;
		  display: table-cell;
      vertical-align: middle;
      padding-right:4%;
		  white-space: nowrap;
      &:last-child{padding-right:0;}
		}
		img {
		  width: 100%;
		}
      // li:hover img{
      //   transform: scale(1.08);
      // }
	}
}
