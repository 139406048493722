.list-category .list-group-item{padding: 0;}
.list-grid{
	>div{padding:0;height:500px;}
	.list-group-item{
		h1{font-size:38px;color:#000;}
		h2{font-size:20px;}
		h6{
			margin:0;
			border-top:0;
			strong{display: none;}
		}
	}
	a{height:100%;padding:38px;display:block;}
	a:hover{
		background:$brand-primary;
		h1,h2,h6{
		color:#fff;
		}
	}
}


main section{
	margin: 72px 0;
}


article.category-news {
	main section ul {
	list-style: none;
	padding:0;
		li{position: relative;padding-left: 20px}
		li:before {
		  content: '▶';
		  font-size: 11px;
		  top: 5px;
		 	color: $brand-primary;
		  position: absolute;
		  left: 0;
		}
	}
}


main{
  color: #676c7b;
  display: block;
  position: relative;
  aside {
	  width: 100%;
	  position: absolute;
	  top: 0;
	  left: 0;
	  .container {
		  height: 0px;
		  overflow: visible;
		  padding: 5px 0 0 890px;
		}
		.meta{
			margin:10px 0 32px;
			font-size: 12px;
		}
	  ul{@extend .list-unstyled;}
	}
}



.interlude{
	img{width:100%;}
}

.list-group-item{
	h6{
		padding: 12px 0;
	  text-transform: uppercase;
	  letter-spacing: 0.2em;
	  border-top: 1px solid #caccd1;
	  margin-bottom: 0;
	  text-transform: uppercase;
	  color: #838994;
  	font-weight: 500;
  	border-top-color: #eaebec;
	}
	h2{
		color:#838994;
	}
	strong{
		  margin-right: 18px;
  position: relative;
  font-weight: 600;
  color: #4c5058;
  &:after{
  	  position: absolute;
	  top: 6px;
	  right: -10px;
	  width: 4px;
	  height: 4px;
	  border-radius: 4px;
	  background-color: #caccd1;
	  display: block;
	  content: " ";
  }
	}
}
