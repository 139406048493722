.banner{
	background-color: rgb(248, 248, 248);
	border-bottom:1px solid rgb(231, 231, 231);
	@media (max-width: breakpoint-max(sm) ) {
		background: $brand-primary;
	}
	@media (max-width: breakpoint-max(md) ) {
		padding:0;
		.container{
			max-width:100%;
			padding:0;
		}
	}

	.navbar-nav{
		@media (min-width: breakpoint-min(sm) ) {
			float: left;
		}
    margin-top: 11px;
		@media (max-width: breakpoint-max(sm) ) {
			.nav-item {
				display:block;
				float:none;
			}
		}
	}
}

.navbar-nav{
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
	.nav-link{
		padding:13px 15px;
		color: $navbar-light-color;
		@media (max-width: breakpoint-max(sm) ) {
			padding:13px 12px;
			&:first-child{
				padding-left:5px;
			}
		}

		@include hover-focus {
			color: $navbar-light-hover-color;
		}

		@media (max-width: breakpoint-max(sm) ) {
			color:#fff;
		}
	}
	.nav-link.active{
		color:#2d2d2d;
	}
	.nav-item + .nav-item{
		margin-left:0;
	}
}

.banner .navbar-brand {
	color:#fff !important;
  fill:currentColor;
	background: $brand-primary;
  padding: 18px 1em;
	@media (max-width: breakpoint-max(sm) ) { padding:10px 0 10px 10px; }
	margin-right: 10px;
	@media (min-width: breakpoint-min(lg) ) {
		margin-left: -($grid-gutter-width / 2);
	}

	svg {
		width: 280px;
		height: 34px;
		vertical-align: middle;
    @media (max-width: breakpoint-max(sm) ) { width:230px; }
	}
}
//
.header-contact{
	float: right;
	margin:13px 0 0 0;
  text-align: center;
	overflow:hidden;
	&:hover {
		p:before {
    opacity: 1;
    transform: translate(0,0);
    transition-delay: .12s;
		}
		span {
	    opacity: 0;
	    transform: scale(0.3);
		}
	}
	p{
		margin: 0;
	  font-size: 13px;
	  text-transform: uppercase;
	  font-weight: 600;
	  letter-spacing: 1.1px;
		position:relative;
		&:before {
	    content: '';
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    text-align: center;
	    opacity: 0;
	    transition: .4s,opacity .6s;
	    content: attr(data-hover);
	    transform: translate(-150%,0);
		}
		span {
	    transition: 0.6s;
	    transition-delay: 0.2s;
		}
  }

	h3{	font-weight: 400;
    letter-spacing:0;
		margin:0;
	  padding:0;
	  font-size: 22px;
	  color: #808285;
	  display: block;
    a{color:$brand-primary;}
	}
}
//
// .page-header{
//   padding: 80px 0 0; //47px?
//   margin: 0;
//   text-align: center;
//   h1{
//   font-weight: 300;
//   font-size: 50px;
//   margin:0;
//   letter-spacing: .1px;
//   color: #000;
//   }
//   p{
//     color: #b2b2b2;
//     font-weight: 400;
//     font-size: 18px;
//     max-width: 590px;
//     margin: 0 auto;
//   }
// }
//
//


// Justified nav links
// -------------------------

.nav-justified {
  width: 100%;

  > li {
    float: none;
    > a {
      text-align: center;
      margin-bottom: 5px;
    }
  }

  > .dropdown .dropdown-menu {
    top: auto;
    left: auto;
  }

  @media (min-width: breakpoint-min(sm)) {
    > li {
      display: table-cell;
      width: 1%;
      > a {
        margin-bottom: 0;
      }
    }
  }
}

.navbar-subnav {
    width: 100%;
    overflow: hidden;
		padding:0;
    margin-bottom:0;
    box-shadow:inset 0 -1px 0 #e3e3e3;
    @media (max-width: breakpoint-max(lg, $grid-breakpoints) ){
    .container{max-width: 100%;}
    }
    li>a{
      color: #adadad;
      width: 100%;
      font-size: 14px;
      text-transform: uppercase;
      padding: 17px 0;
      float: left;
      text-align: center;
      border-right: 1px solid #e3e3e3;
      border-bottom: 1px solid #e3e3e3;
      font-weight: 600;
      letter-spacing: .5px;
			transition: all 0.2s ease-in-out;
      @media (max-width:breakpoint-max(md, $grid-breakpoints) ){
        width:50%;height:53px;margin-bottom:0;
      }

      &:hover{
          color: #2d2d2d;
          box-shadow: inset 0 -2px 0 #3381b7;
      }
    }
    li.current-menu-item>a{
      color: #2d2d2d;
      box-shadow: inset 0 -2px 0 #3381b7;
      background-color: #fff;
    }
    ul li:first-child a {
      border-left: 1px solid #e3e3e3
    }
    li{
    @media (max-width:breakpoint-max(md, $grid-breakpoints) ){
        display:block;width:auto;
      }
    }

}
//
//
//
//
//
// // .navbar-subnav ul a:hover,.navbar-subnav ul a.active {
// //     color: #2d2d2d;
// //     box-shadow: inset 0 -2px 0 #3381b7
// // }
//
// // .navbar-subnav ul a:active {
// //     -webkit-box-shadow: inset 0 -2px 0 #3381b7;
// //     -moz-box-shadow: inset 0 -2px 0 #3381b7;
// //     box-shadow: inset 0 -2px 0 #3381b7
// // }
//
// // .navbar-subnav ul a:after {
// //     content: "";
// //     clear: both
// // }

// Toggler Override for Fonts
.navbar-toggler{
	padding:1em;
	@media (max-width: breakpoint-max(sm) ) { padding:14px; }
	font-family: Helvetica, Arial, sans-serif;
	background:rgba(0,0,0,0);
	color:#fff;
}
