body {
  font-weight: 400;
  // font-size: 17px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: "kern";
  -moz-font-feature-settings: "kern";
  font-feature-settings: "kern";
}
a{ transition:color 0.24s cubic-bezier(0.645, 0.045, 0.355, 1); }
a:hover{text-decoration: none;}

.vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.col-centered{
  float: none;
display: inline-block;
}

.col-centered-form{
  float:none;
  display:block;
  margin:0 auto;
}

.center-block{
  float:none;
}

body.admin-bar{
  .navbar-fixed-top{ top: 28px !important; }
}

::-moz-selection {
    background: #444;
    color: #fff;
    text-shadow: none
}

::selection {
    background: #444;
    color: #fff;
    text-shadow: none
}

.selectedText {
    background: $brand-success;
    font-style: normal;
    padding: 0
}

main{
  color:#676c7b;
}

.container.content{
  margin:96px auto;
}

h1, h2, h3, h4{
	font-weight: 400;
  color:#454545;
}

h2:first-child,h3:first-child,h4:first-child{
  margin-top:0;
}

h3{
  font-weight: 300;
  letter-spacing: 1px;
  a{ color: #1f2526;}
}


h4{
  a{ color: #1f2526;}
}

.col2, .col3,.col4{
    -webkit-column-gap:17px;
    -moz-column-gap:17px;
    -ms-column-gap:17px;
    -o-column-gap:17px;
    column-gap:17px;
    list-style-type: none;
    padding: 0;
}

.col2 {
-webkit-column-count:2;
    -moz-column-count:2;
    -ms-column-count:2;
    -o-column-count:2;
    column-count:2;
    columns:2;
}

.col3 {
-webkit-column-count:3;
    -moz-column-count:3;
    -ms-column-count:3;
    -o-column-count:3;
    column-count:3;
    columns:3;
}

.col4 {
-webkit-column-count:4;
    -moz-column-count:4;
    -ms-column-count:4;
    -o-column-count:4;
    column-count:4;
    columns:4;
}

.embed-responsive-1by1 {
  padding-bottom: 100%;
}
