.pagination {
  display: inline-block;
  padding-left: 0;
  margin-top: $spacer-y;
  margin-bottom: $spacer-y;
  @include border-radius();

  > li {
    display: inline; // Remove list-style and block-level defaults

    > a,
    > span {
      position: relative;
      float: left; // Collapse white-space
      padding: $pagination-padding-y $pagination-padding-x;
      margin-left: -1px;
      line-height: $line-height;
      color: $pagination-color;
      text-decoration: none;
      background-color: $pagination-bg;
      border: 1px solid $pagination-border;
    }
    &:first-child {
      > a,
      > span {
        margin-left: 0;
        @include border-left-radius($border-radius);
      }
    }
    &:last-child {
      > a,
      > span {
        @include border-right-radius($border-radius);
      }
    }
  }

  > li > a,
  > li > span {
    @include hover-focus {
      color: $pagination-hover-color;
      background-color: $pagination-hover-bg;
      border-color: $pagination-hover-border;
    }
  }

  > .active > a,
  > .active > span {
    @include plain-hover-focus {
      z-index: 2;
      color: $pagination-active-color;
      cursor: default;
      background-color: $pagination-active-bg;
      border-color: $pagination-active-border;
    }
  }

  > .disabled {
    > span,
    > a {
      @include plain-hover-focus {
        color: $pagination-disabled-color;
        cursor: $cursor-disabled;
        background-color: $pagination-disabled-bg;
        border-color: $pagination-disabled-border;
      }
    }
  }
}


//
// Sizing
//

.pagination-lg {
  @include pagination-size($pagination-padding-y-lg, $pagination-padding-x-lg, $font-size-lg, $line-height-lg, $border-radius-lg);
}

.pagination-sm {
  @include pagination-size($pagination-padding-y-sm, $pagination-padding-x-sm, $font-size-sm, $line-height-sm, $border-radius-sm);
}
