.excerpt{
  margin-bottom:72px;
}

.call-to-action {
  padding: 93px 0;
  width: 100%;
  text-align: center;
  background-color: $gray-lightest;
}

section.padded{
  padding: 93px 0;
  width: 100%;
  text-align: center;
}

.noheader {
  padding: 140px 0 50px 0;
  p{margin: 0 0 30px;}
}

.jumbotron {
  text-align: center;
  border-bottom: 10px solid #efefef;
  @media (min-width: breakpoint-min(sm) ) {
      padding: 58px 0 72px;
  }
  h1{line-height: 1;color:#000;}
  h3.lead{margin:0;letter-spacing:0;color:#999;}

  &.has-image{
    border-bottom:0;
    padding:0;
    background-color:#04203e;
    position: relative;
    overflow: hidden;
    min-height: 490px;
    width: 100%;
    @media (max-width: breakpoint-max(md) ) {
  		min-height: 350px;
  	}
  	@media (max-width: breakpoint-max(sm) ) {
  		min-height: 240px;
  	}
    @media (max-width: breakpoint-max(xs) ) {
      min-height: 170px;
    }

    h1{color:#fff;}

    .color-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0,0,0,.25);
      z-index: 99;
    }
    .thumbnail {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    	margin:0;
      -webkit-animation: feature-bg-anim .6s ease-in-out;
      animation: feature-bg-anim .6s ease-in-out;
    }
  }

}




.billboard{
	margin:56px 0;
	text-align: center;
	h1 {
	  color: #28282a;
	  font-size: 30px;
	  font-weight:300;
	  margin-bottom: 30px;
	  padding: 0 15px;
	}
	 p {
	  font-size: 19px;
	  line-height: 1.6;
	  color: #757576;
	  margin-bottom: 20px;
	  padding: 0 15px;
	}
}

section {
  &.padded{
    padding:100px 0;
  }
	&.inverse{
		background-color:#2A282C;
		color:#fff;
		h1,h2{color:#aab897;}
		h3{color: white;}
		p{color: #f5f5f5;}
		a{color:#eee;}
		a:hover{color:#fff;}
	}
}

.product-text{
	padding:50px 0;
	p {
	  font-size: 16px;
	  line-height: 1.9;
	  margin: 35px 0;
	}
	h2{
		margin-top:0;
		font-weight:300;
	  font-size: 35px;
	  text-transform: uppercase;
	  letter-spacing: 8px;
	  margin-bottom: 5px;
	  font-weight: normal;
	}
	h3 {
		font-weight:300;
	  font-size: 25px;
	  font-weight: normal;
	}
}

.callout{

	h2{color: #454545;
  font-weight: 600;
  font-size: 26px;
  margin-bottom: 12px;}

  p{color: #717171;
  line-height: 1.4;}
}
