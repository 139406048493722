// Content wrapper
// .wrap { margin-top: 34px; margin-bottom: 60px; }

// Main content area
.main {
  @include make-col();

  @include media-breakpoint-up(sm) {
    @include make-col-span($main-sm-columns);
    .sidebar-primary & {
      @include make-col-span($main-sm-columns - $sidebar-sm-columns);
    }
  }
}

.wrap{
  margin-bottom:100px;
}

@media (min-width: breakpoint-min(sm, $grid-breakpoints)) {
  .column-content {
      -moz-column-count: 2;
      -webkit-column-count: 2;
      column-count: 2;
      -moz-column-gap: 34px;
      -webkit-column-gap: 34px;
      column-gap: 34px;
  }
}

.no-pad{padding:0;}
.no-margin{margin:0;}

section{margin-top: 100px;}

.features{
  .tagline{
    margin:0 0 75px;
    font-weight: 100;
    font-size: 36px;
  }
}

section .side-title {
  color: rgba(193,219,217,.5);
  float: left;
  text-align: right;
  margin-left: -13%;
  margin-top: 90px;
  margin-bottom: -200px;
  width: 200px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  //@include rotate(-90deg);
}

section#extracurricular {
    padding-bottom: 60px;
  margin-top: 100px;

	h4 {
	    color: #ccc;
	}

	ul {
	    padding: 0;
	    list-style: none;
	    font-size: 0.8em;
	    line-height: 1.6em;
	    li {
		    margin-bottom: 1em;
		    padding-right: 30px;
		  	a {
			    text-transform: uppercase;
			    strong {
				    color: #666
				}
			}
	}
}


}
