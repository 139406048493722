//
// Floats
//

.clearfix {
  @include clearfix();
}

.center-block {
  @include center-block();
}

.pull-right {
  @include pull-right();
}

.pull-left {
  @include pull-left();
}


//
// Screenreaders
//

.sr-only {
  @include sr-only();
}

.sr-only-focusable {
  @include sr-only-focusable();
}

// Always hide an element with the `hidden` HTML attribute (from PureCSS).
[hidden] {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

.text-hide {
  @include text-hide();
}


//
// Text
//

// Alignment

.text-left           { text-align: left !important; }
.text-right          { text-align: right !important; }
.text-center         { text-align: center !important; }
.text-justify        { text-align: justify !important; }
.text-nowrap         { white-space: nowrap !important; }
.text-truncate       { @include text-truncate; }

// Responsive alignment

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    .text-#{$breakpoint}-left   { text-align: left; }
    .text-#{$breakpoint}-right  { text-align: right; }
    .text-#{$breakpoint}-center { text-align: center; }
  }
}

// Transformation

.text-lowercase      { text-transform: lowercase; }
.text-uppercase      { text-transform: uppercase; }
.text-capitalize     { text-transform: capitalize; }

// Contextual colors

.text-muted {
  color: $text-muted;
}

@include text-emphasis-variant('.text-primary', $brand-primary);

@include text-emphasis-variant('.text-success', $brand-success);

@include text-emphasis-variant('.text-info', $brand-info);

@include text-emphasis-variant('.text-warning', $brand-warning);

@include text-emphasis-variant('.text-danger', $brand-danger);
