
#email-form {
  width: 100%;
  float: left;
  position: relative;
  color:#fff;

  input[type=email] {
	  color: white;
	  background: none;
	  font-size: 40px;
	  line-height: 2;
	  border:none;
	  border-bottom: 1px dotted #c5ceba;
	  width: 100%;
	  padding-right: 60px;
	  outline: none;
	}

	button {
	  position: absolute;
	  right: 0;
	  top: 28px;
	  background-color: rgba(255,255,255,0.35);
	  width: 30px;
	  height: 30px;
	  border-radius: 50%;
	  background-image: url(footer/submit.png);
	  background-size: 16px 16px;
	  background-repeat: no-repeat;
	  background-position: center center;
	}
}

.gform_wrapper {
	ul {
	  @extend .list-unstyled;
	}

	li {
	  @extend .form-group;
	  &.field_sublabel_below{margin-bottom:20px;}
	}
	form {
	  margin-bottom: 0;
	}
	.gfield_required {
	  padding-left: 1px;
	  color: $state-danger-text;
	}
}

.gfield_label{
  font-weight: bold;
}

.ginput_complex{
	@include clearfix;
}

.name_first{width:50%;float:left;padding-right: 10px;}
.name_last{width:50%;float:left;padding-left: 10px;}


.ginput_container input,
.ginput_container select,
.ginput_container textarea {
	@extend .form-control;
}
.ginput_container textarea {
  height: 195px;
}
.gform_button {
	@extend .btn;
	@extend .btn-primary;
  @extend .btn-md;
  @media (max-width: breakpoint-max(sm) ){
    display:block;width: 100%;
  }
}
.gform_wrapper .gfield_error {
  .gfield_label {
    color: $state-danger-text;
  }
  input,
  select,
  textarea {
    border-color: $alert-danger-border;
    background-color: $alert-danger-bg;
    color: $alert-danger-text;
    // @include form-control-focus($alert-danger-text);
  }
}
.validation_error {
	@extend .alert;
	@extend .alert-danger;
}
#gforms_confirmation_message {
	@extend .alert;
	@extend .alert-success;
}

  ul.gfield_radio, ul.gfield_checkbox {
    li {
      position: relative;
      display: block;
      min-height:35px;
      margin-bottom: 0;
      input{padding-right:0;}
    }

    li input[type="radio"]:checked + label, li input[type="checkbox"]:checked + label {
      font-weight: bold;
    }
  }

    // fix for bootstrap standard of 100% width for checkboxes and radio buttons
    input[type='checkbox'], input[type='radio'] {
      width: auto;
    }

    // Improves UX and layout for checkboxes and radio buttons
  .gfield_checkbox li input[type="checkbox"], .gfield_radio li input[type="radio"], .gfield_checkbox li input {
    float: left;
    margin-top: 5px;
    height: auto;
    &:hover {
      cursor: pointer;
    }
  }

  // Improves UX and layout for labels of checkboxes and radio buttons
  .gfield_checkbox li label, .gfield_radio li label {
    display: block;
    line-height: 1.5;
    margin: 1px 0 0 23px;
    padding: 0;
    vertical-align: top;
    width: auto;
    &:hover {
      cursor: pointer;
    }
  }
