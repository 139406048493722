// Responsive images (ensure images don't scale beyond their parents)
.img-fluid {
  @include img-fluid();
}

// Rounded corners
.img-rounded {
  @include border-radius($border-radius-lg);
}

// Image thumbnails
.img-thumbnail {
  padding: $thumbnail-padding;
  line-height: $line-height;
  background-color: $thumbnail-bg;
  border: 1px solid $thumbnail-border;
  border-radius: $thumbnail-border-radius;
  transition: all .2s ease-in-out;
  @include box-shadow(0 1px 2px rgba(0,0,0,.075));

  // Keep them at most 100% wide
  @include img-fluid(inline-block);
}

// Perfect circle
.img-circle {
  border-radius: 50%;
}
