
.btn{
  font-weight: 600;
  span {
    width: 9px;
    height: 11px;
    display: inline-block;
    background-size: 9px 11px;
    background-image: url("../../assets/images/arrow-right.svg");
    background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='116.5 0 766.9 1000' xml:space='preserve'><path fill='%23FFFFFF' d='M243.3,0L116.5,157.7l390.6,275.5l95.9,60.7l-95.9,71L124.7,840.3L251.5,1000l632-439.1V421.2L243.3,0z'/></svg>");
    margin-left: 6px;
  }
}

.btn-sm{
  text-transform: uppercase;
}

.btn-md{
  padding: 12px 50px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.btn-svbtle {
  font-size: 14px;
  border-radius: 3px;
  padding: 12px 50px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  border: none;
}

.btn-empty{
    background: none;
    color:#fff;
    border-color:rgba(255, 255, 255, 0.4);
    &:hover{
      border-color:#fff;
      color:#fff;
      background: none;
    }
}

.btn-empty-b{
  background: none;
  color:#000;
  border-color:rgba(0, 0, 0, 0.4);
  &:hover{
    border-color:#000;
    color:#000;
    background: none;
  }
}
@media (max-width: breakpoint-max(xs) ){
  .btn-xs-block{display:block;width: 100%;padding-left: 0;padding-right: 0;}
}
