// // WordPress Generated Classes
// // http://codex.wordpress.org/CSS#WordPress_Generated_Classes
//

.pagination {
  > li {
    > a {
  margin-right: 7px;
  height: 43px;
  width: 43px;
  text-align: center;
  line-height: 43px;
  font-weight: 600;
  border-radius: 4px;
  //font-size: 18px;
  padding:0;
  transition:all 0.2s ease-in-out;
    }
    &:first-child, &:last-child  {
      > a {
          text-transform: uppercase;
          color: #3e3e3e;
          font-weight: 700;
          padding: 0 20px;
          font-size: 15px;
          float: left;
          width:auto;
          background-color: transparent;
          border: 0;
      }
    }

  }
}
