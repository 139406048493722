body.page-what-we-do .banner-thumbnail-wrapper {
  margin-top: -2px;
}

.callout{
  figure{
    margin: 48px 0 0;
  }
  svg{
    width:150px;
    height:150px;
    vertical-align: bottom;
    stroke: #1f2526;
  }
  p{
    padding:0 20px;
    text-align: justify;
  }
}

.brands{
	img{
		max-width:100%;
		-webkit-filter: grayscale(100%);
	  -webkit-transition: -webkit-filter 0.15s ease-out;
		&:hover {
		  -webkit-filter: grayscale(0);
		}
	}
}

.how-we-help{
  margin: 49px 0;
  padding:49px 0;
  border-top:1px solid rgba(31,31,31,.15);
  border-bottom:1px solid rgba(31,31,31,.15);

  h4{margin-top:57px;}
  h4:first-child{margin-top:0;}
}


.contact-us-cta{
  background-color:#3682B9;
  color:#FFF;
  padding: 100px 0;
  margin:0;
  .cta-row{
    display: table;
    width: 100%;
  }
  .cta-left, .cta-right{
    display:block;
    @media (min-width: breakpoint-min(sm) ) {
      display: table-cell;
      vertical-align: middle;
    }
  }

    .cta-left{
      @media (min-width: breakpoint-min(lg) ) {
        padding-right: 200px;
      }
      @media (max-width: breakpoint-max(xs) ) {
        padding-bottom: ($spacer-y * 2.5);
      }
    }

  h2{
    color:#fff;
    margin: 0;

    @media (max-width: breakpoint-max(xs) ) {
      padding-bottom: ($spacer-y * 1);
    }
  }

  p{padding-top: 4px;margin:0;}
  .btn{
    color:#3682B9;
    background-color:#fff;
    transition:background-color 0.24s cubic-bezier(0.645, 0.045, 0.355, 1);
    &:hover{
      background-color:rgba(255,255,255,0.9);
    }
  }
}
