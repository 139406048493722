.contact{
  .details {
    background: $gray-darker;
    margin: 0;
    padding: 0;
    color: #fff;
    text-align: center;
    ul{
    	list-style: none;
      margin: 0;
      padding:0;
      li {
        float: left;
        display: block;
        margin-right: 1.88679%;
        width: 49.0566%;
        &:last-child {
          margin-right: 0
        }
      }
    }

    dl {
      display: inline-block;
      margin: 0 10px;
      dt {
        margin-right: 10px;
        text-transform: uppercase;
        font-weight: 400;
      }
      dd {
      	font-weight: 600;
      }
    }
  }


  .details dl dt,.details dl dd {
    display: inline-block;
    vertical-align: middle;
    line-height: 50px;
    margin:0;
  }


  .map {
    background: url("../images/JohnsonAir_Clovis_Map.png") no-repeat 50% 50%;
    background-size: auto 100%;
    height: 400px;
    text-align: center;
    margin-top: 0;
  }
}
