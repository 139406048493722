#footer {
  padding: 40px 0 60px;
  font-size: 16px;
  // border-top: solid 1px #eeeff0;
  background: $gray-darker;
  color: $gray;

  h6{
  	color: #fff;
    text-transform: uppercase;
    font-weight: 600;
  }
  ul li a{
    padding: 0 0 7px;
    @media (max-width: breakpoint-max(xs) ) {
      padding: 7px 0 7px;
      display:inline-block;
    }
    font-weight:600;
    color:$gray-lighter;
    &:hover{
      color:#fff;
    }
  }

  li .withLabel .highlight {
    display: inline-block;
    margin-left: 10px;
    padding: 0 7px;
    height: 20px;
    line-height: 20px;
    vertical-align: middle;
    font-size: 13px;
    color: $btn-success-color;
    background: $brand-success;
    border-radius: 3px;
    text-decoration: none;
  }


  .border-container {
    display: block;
    border-bottom: 1px solid #6f7575;
    padding: 0 0 25px;
    margin: 0 0 30px;
    line-height: 20px;
    vertical-align: middle;
    list-style: none;
    color:$gray-lighter;
    fill: currentColor;
    li {
      display: inline-block;
      margin-left: .25em;
      margin-right: .25em;
      vertical-align: middle;
      line-height: 21px;
      height: 20px;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: .5px;
      a{
        color:$gray-lighter;
        fill: currentColor;
      }
    }
  }



 .icon-arrow-right-gray {
  height: 20px;
  width: 20px;
}

   .jair-2015 {
    width: 166px;
    height: 20px;
  }
}
