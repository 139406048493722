.container.with-sidebar{
	margin-top: 70px;
	margin-bottom: 70px;
	.article{padding-left: 60px;}
}

#static-nav {
	font-size:15px;
	nav{
		strong{
			@extend .h6;
			text-transform: uppercase;
			display: block;
	  margin-bottom: 20px;
	  padding-bottom: 10px;
	  font-weight: 600;
	  border-bottom: solid 1px rgba(0,0,0,0.05);
		}
		ul{
			list-style: none;
			padding:0;
			li{
			  margin: 6px 0;
	  		list-style-type: none;
	  		a{
	  			&.active{
	  				padding-left: 15px;
					  border-left: solid 2px #676c7b;
					  color: #676c7b;
					}
	  		}
			}
		}
	}
}




.text-wrapper {
  -webkit-animation: feature-text-anim .75s ease-in-out;
  animation: feature-text-anim .75s ease-in-out;
  z-index: 100;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
  // max-width: 956px;
  // margin: 0 auto;
  // text-align: center;
  // text-rendering: optimizeLegibility;
	div{
		margin:0;
		text-align: center;
		position: absolute;
    display: block;
    top: 50%;
    width: 100%;
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
	}


	p {
	  font-size: 28px;
	  line-height: 1.3em;
	  letter-spacing: 1px;
	  font-weight: 300;
	  font-style: normal;
	  color: #fff;
	  margin: 20px auto;
		@media (max-width: breakpoint-max(sm) ) {
			font-size: 18px;
			margin-top:0;
		}
	}

	h1{
	  font-size: 66px;
	  line-height: 1.1em;
	  text-transform: none;
	  font-weight: 300;
	  color: #fff;
	  letter-spacing: 1px;
	  @media (max-width: breakpoint-max(sm) ) {
			margin:0;
	    font-size:36px;
	  }
	}

}
