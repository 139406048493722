@keyframes feature-text-anim{
  0%{opacity:0;transform:translate3d(0,10px,0)}
  75%{opacity:0;transform:translate3d(0,10px,0)}
  100%{opacity:1;transform:translate3d(0,0,0)}
}


@keyframes feature-bg-anim{
  0%{opacity:0}
  50%{opacity:0}
  100%{opacity:1}
}
